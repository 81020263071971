@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  background: #fbf9ee;
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 400;
  letter-spacing: 0.1em;
  color: #333;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 16px;
}

@media only screen and (max-width: 640px) {
  body {
    font-size: 12px;
  }
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  margin-bottom: 150px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #contents_wrap {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 640px) {
  #contents_wrap {
    margin-bottom: 50px;
  }
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
}

@media only screen and (max-width: 834px) {
  header#global_header {
    padding-top: 48px;
    border-top: 0;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 834px) {
  .pcnone {
    display: block;
  }
}

#hednav {
  background: white !important;
  border-bottom: 1px solid #ddd;
  width: 100%;
  padding: 25px 10px;
}

#hednav .navwrap {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 834px) {
  #hednav .navwrap {
    margin: 0;
  }
}

#hednav .navwrap #siteID2 {
  width: 20%;
  display: block;
  text-align: left;
}

@media only screen and (max-width: 1200px) {
  #hednav .navwrap #siteID2 {
    width: 20%;
  }
}

@media only screen and (max-width: 834px) {
  #hednav .navwrap #siteID2 {
    display: none;
  }
}

#hednav .navwrap #siteID2 img {
  width: 100% !important;
  max-width: calc( 357px / 2);
}

#hednav .navwrap #nav_global {
  width: 60%;
}

@media only screen and (max-width: 1200px) {
  #hednav .navwrap #nav_global {
    width: 60%;
  }
}

#hednav .navwrap #hed_info {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1000px) {
  #hednav .navwrap #hed_info {
    width: auto;
  }
}

#hednav .navwrap #hed_info .store1 a {
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: 200;
  display: block;
  background: #052984;
  color: #fff;
  font-weight: 500;
  padding: 5px 10px;
  font-size: 14px;
  font-size: 14px;
  font-size: 0.875vw;
}

@media only screen and (min-width: 1600px) {
  #hednav .navwrap #hed_info .store1 a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 834px) {
  #hednav .navwrap #hed_info .store1 a span {
    display: none;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #hednav .navwrap #hed_info .store1 a {
    font-size: 13px;
  }
}

@media only screen and (max-width: 640px) {
  #hednav .navwrap #hed_info .store1 a {
    font-size: 13px;
  }
}

#hednav .navwrap #hed_info .store1 a i {
  margin-right: 5px;
  font-size: 13px;
}

#hednav .navwrap #hed_info .store2, #hednav .navwrap #hed_info .store3 {
  margin-left: 5px;
}

#hednav .navwrap #hed_info .store2 a, #hednav .navwrap #hed_info .store3 a {
  display: block;
  font-size: 30px;
  color: #052984;
}

.fixed {
  position: fixed;
  z-index: 11;
  top: 0;
}

#nav_global {
  z-index: 10;
  position: relative;
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#nav_global ul li {
  position: relative;
  flex-grow: 1;
}

#nav_global ul li a {
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: 200;
  display: block;
  color: #6a4306;
  padding: 0 10px;
  font-size: 16px;
  font-size: 1vw;
}

@media only screen and (min-width: 1600px) {
  #nav_global ul li a {
    font-size: 16px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #nav_global ul li a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 640px) {
  #nav_global ul li a {
    font-size: 14px;
  }
}

#nav_global ul li a:hover {
  transition: 0.5s;
}

#nav_global ul li:last-child a {
  border: 0;
}

#nav_global ul li ul {
  position: absolute;
  width: 260px;
  top: 44px;
  right: -50px;
  margin: auto;
  display: flex;
  flex-flow: column;
}

@media only screen and (max-width: 834px) {
  #nav_global ul li ul {
    right: 0;
  }
}

#nav_global ul li ul li {
  width: 100%;
}

#nav_global ul li ul li a {
  width: 100%;
  font-size: 13px;
  color: #fff !important;
  display: block;
  padding: 15px;
}

#nav_global ul li ul li a:hover {
  transition: 0.5s;
}

.global-menu-btn {
  display: none;
  font-size: 10px;
}

@media only screen and (max-width: 834px) {
  .global-menu-btn {
    z-index: 11;
    display: block;
    position: absolute;
    left: 5px;
    top: 5px;
    color: #b5a766;
    line-height: 1;
  }
  .global-menu-btn::before {
    font-size: 2.4em;
    content: "\f0c9";
    line-height: 1;
    font-family: "Font Awesome 5 pro";
    font-weight: 400;
  }
  .global-menu-btn i {
    margin-top: 3px;
    display: block;
    line-height: 1;
  }
}

.global-menu-close-btn {
  display: none;
}

@media only screen and (max-width: 834px) {
  .global-menu-close-btn {
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    -webkit-transform: translateX(70px);
    -ms-transform: translateX(70px);
    transform: translateX(70px);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
  }
  .global-menu-close-btn:before {
    content: "\f00d";
    line-height: 32px;
    font-family: "Font Awesome 5 pro";
    font-size: 1.3rem;
    font-weight: 300;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .global-menu-close-btn i {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  #hednav {
    justify-content: center;
    height: 48px;
    width: 100%;
    position: fixed;
    z-index: 11;
    top: 0;
    margin: 0;
    background: #fff;
    border-bottom: 1px solid #ddd;
  }
  #hednav .wrap2 {
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    background: rgba(0, 0, 0, 0.6) !important;
    display: block !important;
    position: fixed;
    width: 100% !important;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  #hednav .wrap2 .navwrap {
    display: block !important;
    background: #fff;
    width: 75%;
    height: 100%;
    opacity: 0;
    -webkit-transform: translateX(-240%);
    -ms-transform: translateX(-240%);
    transform: translateX(-240%);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  #hednav .wrap2 .navwrap #hed_info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  #hednav .wrap2 .navwrap #hed_info .store1 a {
    background: #b5a766;
  }
  #hednav .wrap2 .navwrap #hed_info .store2 p {
    background: #c3b56e;
  }
  #hednav .wrap2 .navwrap #hed_info .store3 p {
    background: #b5a766;
  }
  #hednav .wrap2 .navwrap #hed_info .hoverbtn {
    width: 50%;
    margin: 0 !important;
  }
  #hednav .wrap2 .navwrap #hed_info .hoverbtn a {
    padding: 10px 0;
    font-weight: 300;
    font-size: 0.5rem !important;
    border: 0 !important;
    color: #fff !important;
    transition: 1s;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  #hednav .wrap2 .navwrap #hed_info .hoverbtn a i {
    font-weight: 300;
    font-size: 1.5rem !important;
    margin: 0 0 5px 0 !important;
  }
  #hednav .wrap2 .navwrap #nav_global {
    width: 100%;
    margin-top: 0 !important;
  }
  #hednav .wrap2 .navwrap #nav_global ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
  }
  #hednav .wrap2 .navwrap #nav_global ul li {
    position: relative;
    width: 100%;
    height: 80px;
    border-bottom: solid 1px #ddd;
  }
  #hednav .wrap2 .navwrap #nav_global ul li a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-left: 70px;
    line-height: 1;
    height: 100%;
    font-weight: 300;
    font-size: 0.8rem !important;
  }
  #hednav .wrap2 .navwrap #nav_global ul li a::before {
    display: block;
    position: absolute;
    left: 15px;
    top: 35%;
    z-index: 1;
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
    font-family: channel-icons;
    font-size: 1.7rem;
    font-family: "Font Awesome 5 pro";
    color: #b5a766;
  }
  #hednav .wrap2 .navwrap #nav_global ul li:nth-child(1) a::before {
    content: "\f004";
  }
  #hednav .wrap2 .navwrap #nav_global ul li:nth-child(2) a::before {
    left: 16px;
    content: "\f290";
  }
  #hednav .wrap2 .navwrap #nav_global ul li:nth-child(3) a::before {
    left: 12px;
    content: "\f553";
  }
  #hednav .wrap2 .navwrap #nav_global ul li:nth-child(4) a::before {
    left: 10px;
    content: "\f4a1";
  }
  #hednav .wrap2 .navwrap #nav_global ul li:nth-child(5) a::before {
    content: "\f733";
  }
  #hednav .wrap2 .navwrap #nav_global ul li:nth-child(6) a::before {
    left: 13px;
    content: "\f2bd";
  }
  #hednav .wrap2 .navwrap #nav_global ul li:nth-child(7) a::before {
    left: 12px;
    content: "\f4be";
  }
  #hednav .wrap2 .navwrap #nav_global ul li:nth-child(8) a::before {
    content: "\f236";
  }
}

.MenuOpened {
  z-index: 13 !important;
}

.MenuOpened .global-menu-btn {
  z-index: 0;
}

.MenuOpened .wrap2 {
  z-index: 10 !important;
  visibility: visible !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.MenuOpened .wrap2 .navwrap {
  opacity: 1 !important;
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  transform: translateX(0) !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh !important;
}

.MenuOpened .wrap2 .global-menu-close-btn {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

#mainArea {
  width: 100%;
  position: relative;
}

#mainArea #header {
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px;
}

@media only screen and (max-width: 834px) {
  #mainArea #header {
    position: fixed;
    top: 0;
    right: 10px;
    left: auto;
    z-index: 11;
    padding: 0;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

#mainArea #header #siteID {
  display: none;
}

@media only screen and (max-width: 834px) {
  #mainArea #header #siteID {
    display: block;
  }
}

#mainArea #header #siteID a {
  opacity: 1 !important;
}

#mainArea #header #siteID img {
  width: 100% !important;
  max-width: calc( 357px / 2);
}

#mainArea img {
  width: 100% !important;
  height: auto;
}

#mainArea .mainviage img {
  width: auto !important;
}

#local-keyvisual {
  position: relative;
  background-image: url(../images/common/main_1.jpg);
  background-position: center;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual {
    margin-bottom: 50px;
  }
}

#local-keyvisual #local-keyvisual-title {
  position: relative;
  z-index: 1;
}

#local-keyvisual h1 {
  color: #fff;
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  letter-spacing: 0.1em;
  font-weight: 400;
  line-height: 1.7em;
  position: relative;
  background: rgba(0, 0, 0, 0.2);
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 45px;
  font-size: 2.8125vw;
}

@media only screen and (min-width: 1600px) {
  #local-keyvisual h1 {
    font-size: 45px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 35px;
  }
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 2em;
  }
}

#local-keyvisual h1 span {
  padding: 150px 0;
  display: block;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 span {
    padding: 100px 0;
  }
}

#local-keyvisual h1 span:empty {
  display: none;
}

.mainviage h1 {
  background: rgba(255, 255, 255, 0.3) !important;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-size: cover;
}

.pan1 {
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  margin: 50px 0;
  color: #333;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #333;
}

footer#global_footer {
  min-width: 100%;
}

#f_global {
  max-width: 1000px;
  margin: 0 auto 30px;
}

@media only screen and (max-width: 1000px) {
  #f_global {
    padding: 0 10px;
  }
}

#f_global ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

#f_global ul li a {
  color: #333;
}

#copy {
  display: block;
}

#copy a {
  text-decoration: none;
  color: #333;
}

#copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #copy a {
    line-height: 60px;
  }
}

.txt {
  line-height: 3em;
  padding: 0 10px;
}

.bnrwrap {
  text-align: center;
  line-height: 1;
}

@media only screen and (max-width: 1200px) {
  .bnrwrap {
    padding: 0 10px;
  }
}

.bnrwrap .bnr {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.bnrwrap .bnr a {
  display: block;
}

.bnrwrap .bnr a img {
  width: 100% !important;
  max-width: calc( 1320px / 2);
  transition: 1s;
}

.bnrwrap .bnr a img:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.bnrwrap .bnr:after {
  display: none;
}

.bnrwrap .bnr div {
  margin: 10px;
  border: 1px solid #fff;
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #fff;
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  letter-spacing: 0.2em;
  font-weight: 400;
  line-height: 1.7em;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .bnrwrap .bnr div {
    font-size: 30px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bnrwrap .bnr div {
    font-size: 25px;
  }
}

@media only screen and (max-width: 640px) {
  .bnrwrap .bnr div {
    font-size: 20px;
  }
}

.bnrwrap .bnr div:after {
  content: "";
  height: 2px;
  width: 30px;
  display: block;
  background: #fff;
  margin: 15px auto 0;
  visibility: inherit;
}

.bg01 {
  height: 550px;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  margin-top: 150px;
}

@media only screen and (max-width: 1200px) {
  .bg01 {
    height: 500px;
  }
}

@media only screen and (max-width: 1000px) {
  .bg01 {
    height: 350px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bg01 {
    margin-top: 100px;
    height: 300px;
    background-size: cover;
    background-attachment: inherit;
  }
}

@media only screen and (max-width: 640px) {
  .bg01 {
    height: 300px;
    margin-top: 50px;
    background-size: cover;
    background-attachment: inherit;
  }
}

.cont_1 {
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .cont_1 {
    display: block;
  }
}

.cont_1 .flxL {
  position: relative;
  width: 40%;
}

@media only screen and (max-width: 640px) {
  .cont_1 .flxL {
    margin-bottom: 30px;
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .cont_1 .flxL .txt2 {
    margin-right: 50% !important;
  }
}

.cont_1 .flxL .img02 {
  text-align: right;
}

@media only screen and (max-width: 640px) {
  .cont_1 .flxL .img02 {
    position: absolute;
    right: 0;
    top: 0px;
    z-index: -1;
    margin: 0 !important;
  }
}

.cont_1 .flxL .img02 img {
  float: none !important;
  margin-left: 0 !important;
  max-width: 100% !important;
}

@media only screen and (max-width: 640px) {
  .cont_1 .flxL .img02 img {
    max-width: 100% !important;
  }
}

.cont_1 .flxR {
  width: 57%;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .cont_1 .flxR {
    width: 100%;
  }
}

.cont_1 .flxR img {
  float: none !important;
  margin-right: 0 !important;
  max-width: 100% !important;
}

.cont_1 .flxR .img {
  position: absolute;
  bottom: -220px;
  right: 0;
}

@media only screen and (max-width: 834px) {
  .cont_1 .flxR .img {
    display: none;
    position: static;
  }
}

.btn {
  width: 50%;
}

@media only screen and (max-width: 834px) {
  .btn {
    width: 100%;
  }
}

.btn a {
  display: block;
  background: #052984;
  padding: 10px;
  transition: 1s;
  color: #fff;
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  letter-spacing: 0.1em;
  font-weight: 400;
  line-height: 1.7em;
  font-size: 14px;
  transition: 2s;
}

.btn a:hover {
  opacity: 1;
  background: #0c2666;
}

.btn a i {
  font-size: 16px;
  margin-right: 5px;
}

.bnr02 {
  max-width: 1200px;
  text-align: center;
  line-height: 1;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 640px) {
  .bnr02 {
    display: block;
  }
}

.bnr02 .box {
  overflow: hidden;
  position: relative;
  width: 32%;
}

@media only screen and (max-width: 640px) {
  .bnr02 .box {
    width: 100%;
    margin-bottom: 30px;
  }
}

.bnr02 .box a {
  display: block;
}

.bnr02 .box a img {
  width: 100% !important;
  max-width: calc( 1320px / 2);
  transition: 1s;
}

@media only screen and (max-width: 640px) {
  .bnr02 .box a img {
    height: 195px !important;
    font-family: 'object-fit: cover;';
    object-fit: cover;
  }
}

.bnr02 .box a img:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.bnr02 .box:after {
  display: none;
}

.bnr02 .box div {
  margin: 10px;
  border: 1px solid #fff;
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #fff;
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  letter-spacing: 0.2em;
  font-weight: 400;
  line-height: 1.7em;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .bnr02 .box div {
    font-size: 30px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bnr02 .box div {
    font-size: 25px;
  }
}

@media only screen and (max-width: 640px) {
  .bnr02 .box div {
    font-size: 20px;
  }
}

.bnr02 .box div:after {
  content: "";
  height: 2px;
  width: 30px;
  display: block;
  background: #fff;
  margin: 15px auto 0;
  visibility: inherit;
}

.news {
  margin: 0 auto;
  max-width: 1200px;
}

.news .scl {
  display: block !important;
  height: 500px;
  overflow-y: scroll;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .news .scl {
    height: 500px;
  }
}

@media only screen and (max-width: 640px) {
  .news .scl {
    height: 350px;
  }
}

.news .scl .box {
  width: 100%;
  margin-bottom: 20px;
}

.news .scl .box article {
  width: 100%;
}

.news .scl .box article .ttl {
  font-weight: 700;
  display: block;
  margin-bottom: 10px;
  padding-left: 20px;
  width: 85%;
  float: left;
}

@media only screen and (max-width: 1000px) {
  .news .scl .box article .ttl {
    width: 80%;
  }
}

.news .scl .box article .ttl span {
  color: #0c2666;
}

.news .scl .box article div {
  padding-left: 20px;
  width: 85%;
  float: left;
}

@media only screen and (max-width: 1000px) {
  .news .scl .box article div {
    width: 80%;
  }
}

.news .scl .box img {
  display: block;
  width: 15% !important;
  float: left;
  height: 150px !important;
  font-family: 'object-fit: cover;';
  object-fit: cover;
}

@media only screen and (max-width: 1000px) {
  .news .scl .box img {
    width: 18% !important;
    height: 15vw !important;
  }
}

.cont_2 {
  background: #f2efe0;
  padding: 50px 0;
}

@media only screen and (max-width: 640px) {
  .cont_2 {
    background: none;
    padding: 0;
  }
}

.cont_2 .btn {
  width: 25%;
  margin: 30px auto 0;
}

@media only screen and (max-width: 640px) {
  .cont_2 .btn {
    width: 100%;
  }
}

.cont_2 .sns .sns_list {
  border: 0 !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -2.04082% !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_2 .sns .sns_list {
    margin-left: -2.04082% !important;
  }
}

.cont_2 .sns .sns_list > div {
  border-bottom: 0 !important;
  width: 17.95918% !important;
  margin-left: 2.04082% !important;
  margin-bottom: 2.04082% !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_2 .sns .sns_list > div {
    width: 17.95918% !important;
    margin-left: 2.04082% !important;
    display: block;
  }
}

.cont_2 .sns .sns_list > div img {
  width: 100% !important;
  height: 12vw !important;
  font-family: 'object-fit: cover;';
  object-fit: cover;
}

@media only screen and (max-width: 1200px) {
  .cont_2 .sns .sns_list > div img {
    height: 15vw !important;
  }
}

@media only screen and (max-width: 1000px) {
  .cont_2 .sns .sns_list > div img {
    height: 17vw !important;
  }
}

@media only screen and (max-width: 640px) {
  .cont_2 .sns .sns_list > div img {
    height: 27vw !important;
  }
}

.cont_2 .sns .sns_list .sns_photo {
  padding: 0 !important;
  width: 100% !important;
}

.cont_2 .sns .sns_list .sns_text {
  display: none;
}

.cont_3 {
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (max-width: 834px) {
  .cont_3 {
    padding: 0;
  }
}

@media only screen and (max-width: 640px) {
  .cont_3 {
    display: block;
  }
}

.cont_3 .flxL {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_3 .flxL {
    width: 100%;
  }
}

.cont_3 .flxL .scl {
  height: 620px;
  overflow-y: scroll;
  padding: 10px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .cont_3 .flxL .scl {
    height: 490px;
  }
}

@media only screen and (max-width: 640px) {
  .cont_3 .flxL .scl {
    height: 350px;
    margin-bottom: 50px;
  }
}

.cont_3 .flxL .scl .box {
  margin-bottom: 20px;
}

.cont_3 .flxL .scl .box article h3 {
  padding-left: 20px;
  width: 85%;
  float: left;
}

@media only screen and (max-width: 1000px) {
  .cont_3 .flxL .scl .box article h3 {
    width: 80%;
  }
}

.cont_3 .flxL .scl .box article div {
  padding-left: 20px;
  width: 85%;
  float: left;
}

@media only screen and (max-width: 1000px) {
  .cont_3 .flxL .scl .box article div {
    width: 80%;
  }
}

.cont_3 .flxL .scl .box img {
  float: left;
  height: 150px !important;
  font-family: 'object-fit: cover;';
  object-fit: cover;
  width: 15% !important;
}

@media only screen and (max-width: 1000px) {
  .cont_3 .flxL .scl .box img {
    width: 18% !important;
    height: 15vw !important;
  }
}

.cont_3 .flxR {
  width: 48%;
}

@media only screen and (max-width: 640px) {
  .cont_3 .flxR {
    width: 100%;
  }
}

.cont_3 .flxR .btn {
  margin: 20px auto 0;
  width: 100% !important;
}

.slide-bottom {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1500ms;
}

.slide-bottom.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-top {
  opacity: 0;
  transform: translate(0, -50px);
  transition: all 1500ms;
}

.slide-top.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-right {
  opacity: 0;
  transform: translate(50px, 0px);
  transition: all 1500ms;
}

.slide-right.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-left {
  opacity: 0;
  transform: translate(-50px, 0px);
  transition: all 1500ms;
}

.slide-left.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

@keyframes fadeIn_left {
  0% {
    opacity: 0;
    transform: translate(-50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn_right {
  0% {
    opacity: 0;
    transform: translate(50px, 0);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
  }
}

/* title */
.midashi {
  padding: 0 10px;
  margin: 0 0 40px 0;
}

@media only screen and (max-width: 834px) {
  .midashi {
    padding: 0;
  }
}

@media only screen and (max-width: 640px) {
  .midashi {
    margin-bottom: 20px;
  }
}

.midashi:after {
  content: "";
  height: 2px;
  width: 30px;
  display: block;
  background: #6a4306;
  margin: 20px auto 0;
  visibility: inherit;
}

.midashi h2 {
  color: #6a4306;
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  letter-spacing: 0.1em;
  font-weight: 500;
  line-height: 1.7em;
  font-size: 45px;
  font-size: 2.8125vw;
}

@media only screen and (min-width: 1600px) {
  .midashi h2 {
    font-size: 45px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .midashi h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi h2 {
    font-size: 35px;
  }
}

.midashi h3 {
  color: #6a4306;
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  letter-spacing: 0.1em;
  font-weight: 500;
  line-height: 1.7em;
  font-size: 45px;
  font-size: 2.8125vw;
}

@media only screen and (min-width: 1600px) {
  .midashi h3 {
    font-size: 45px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .midashi h3 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi h3 {
    font-size: 35px;
  }
}

.midashi2 {
  border-top: 3px double #6a4306;
  border-bottom: 1px solid #6a4306;
  padding: 15px 0;
}

.midashi2 h2 {
  color: #6a4306;
  font-family: "Times New Roman", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  letter-spacing: 0.1em;
  font-weight: 500;
  line-height: 1.7em;
  font-size: 30px;
  font-size: 1.875vw;
}

@media only screen and (min-width: 1600px) {
  .midashi2 h2 {
    font-size: 30px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .midashi2 h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 640px) {
  .midashi2 h2 {
    font-size: 20px;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

.company {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .company {
    display: block;
  }
}

.company .flxL {
  width: 50%;
  height: 100%;
}

@media only screen and (max-width: 834px) {
  .company .flxL {
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .company .flxL {
    margin-bottom: 20px;
  }
}

.company .flxR {
  width: 48%;
}

@media only screen and (max-width: 834px) {
  .company .flxR {
    width: 100%;
  }
}

.comp dl {
  border-bottom: 1px solid #ccc;
}

.comp dl:nth-child(1) {
  border-top: 1px solid #ccc;
}

.comp dl dt {
  padding: 15px !important;
  border: 0 !important;
  background-color: inherit !important;
}

.comp dl dd {
  padding: 15px !important;
  border: 0 !important;
  background-color: inherit !important;
}

.comp dl a {
  color: #b2b2b2;
}

.waku {
  background: #f2efe0;
  padding: 50px;
  line-height: 3em;
  font-size: 15px;
}

@media only screen and (max-width: 834px) {
  .waku {
    padding: 30px;
  }
}

.qa {
  margin-bottom: 30px;
  color: #5d627b;
  display: block !important;
}

@media only screen and (max-width: 640px) {
  .qa {
    text-align: left !important;
  }
}

.qa .q {
  margin-bottom: 20px;
  position: relative;
  display: flex !important;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  border: 0px !important;
  width: 100% !important;
  padding-left: 60px !important;
}

.qa .q:before {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  width: 50px;
  min-width: 50px;
  height: 50px;
  background: #00bcde;
  content: "Q";
  font-size: 18px;
  margin-right: 7px;
}

.qa .a {
  position: relative;
  display: flex !important;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  border: 0px !important;
  width: 100% !important;
  float: left;
  padding-left: 60px !important;
}

.qa .a:before {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  min-width: 50px;
  background: #0b1f56;
  content: "A";
  font-size: 18px;
  margin-right: 7px;
}

.flow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -2.43902% !important;
}

@media only screen and (max-width: 834px) {
  .flow {
    justify-content: space-between;
    margin-left: 0 !important;
  }
}

.flow .box {
  background: #f2efe0;
  padding: 25px;
  border-bottom: 0 !important;
  width: 30.89431% !important;
  margin-left: 2.43902% !important;
  margin-bottom: 2.43902% !important;
}

@media only screen and (max-width: 834px) {
  .flow .box {
    width: 48% !important;
    margin-left: 0 !important;
    display: block;
  }
}

.flow .box:nth-child(1):before {
  content: "1";
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #fff;
  background: #6a4306;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.flow .box:nth-child(2):before {
  content: "2";
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #fff;
  background: #6a4306;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.flow .box:nth-child(3):before {
  content: "3";
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #fff;
  background: #6a4306;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.flow .box:nth-child(4):before {
  content: "4";
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #fff;
  background: #6a4306;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.flow .box:nth-child(5):before {
  content: "5";
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #fff;
  background: #6a4306;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.flow .box:nth-child(6):before {
  content: "6";
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #fff;
  background: #6a4306;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.flow .box:nth-child(7):before {
  content: "7";
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #fff;
  background: #6a4306;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.flow .box:nth-child(8):before {
  content: "8";
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #fff;
  background: #6a4306;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.flow .box:nth-child(9):before {
  content: "9";
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #fff;
  background: #6a4306;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.flow .box article {
  text-align: center;
}

.flow .box img {
  padding: 10px;
  margin-bottom: 10px;
}

.flow .box h3 {
  margin-bottom: 10px;
  color: #6a4306;
  letter-spacing: 0.1em;
  font-weight: 700;
  line-height: 1.7em;
  font-size: 20px;
  font-size: 1.25vw;
}

@media only screen and (min-width: 1600px) {
  .flow .box h3 {
    font-size: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .flow .box h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 640px) {
  .flow .box h3 {
    font-size: 16px;
  }
}

.oemodm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .oemodm {
    display: block;
  }
}

.oemodm .flxL {
  background: #f2efe0;
  padding: 25px;
  width: 49%;
}

@media only screen and (max-width: 834px) {
  .oemodm .flxL {
    width: 100%;
    margin-bottom: 20px;
  }
}

.oemodm .flxR {
  background: #f2efe0;
  padding: 25px;
  width: 49%;
}

@media only screen and (max-width: 834px) {
  .oemodm .flxR {
    width: 100%;
  }
}

.media .box {
  padding: 30px 0;
  border-top: 1px solid #ddd;
}

.media .box article h3 {
  font-weight: 500;
  line-height: 1.7em;
  letter-spacing: 0.2em;
  padding-left: 20px;
  width: 100%;
  float: left;
  margin-bottom: 15px;
  font-size: 20px;
  font-size: 1.25vw;
}

@media only screen and (min-width: 1600px) {
  .media .box article h3 {
    font-size: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .media .box article h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .media .box article h3 {
    line-height: 1.7em;
    font-size: 16px;
  }
}

.media .box article div {
  padding-left: 20px;
  width: 100%;
  float: left;
}

.media:last-child {
  border-bottom: 1px solid #ddd;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.center {
  text-align: center;
}

.cont_8 {
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .cont_8 {
    display: block;
  }
}

.cont_8:nth-child(even) {
  flex-direction: row-reverse;
}

.cont_8 .flxL {
  width: 50%;
}

@media only screen and (max-width: 640px) {
  .cont_8 .flxL {
    width: 100%;
  }
}

.cont_8 .flxR {
  width: 45%;
}

@media only screen and (max-width: 640px) {
  .cont_8 .flxR {
    width: 100%;
  }
}

.cont_8 .flxR img {
  width: 100% !important;
}

/* box */
.item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: -1.63934% !important;
}

@media only screen and (max-width: 834px) {
  .item {
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 834px) {
  .item .wrap2 {
    justify-content: space-between;
  }
}

.item .box {
  width: 23.36066% !important;
  margin-left: 1.63934% !important;
  margin-bottom: 1.63934% !important;
}

@media only screen and (max-width: 834px) {
  .item .box {
    width: 48% !important;
    margin-left: 0 !important;
    margin-bottom: 30px !important;
    display: block;
  }
}

.item .box img {
  width: 100% !important;
}

.item .box span {
  display: inline-block;
  width: 25%;
  color: #fff;
  background: #ccc;
  padding: 5px;
  text-align: center;
}

@media only screen and (max-width: 640px) {
  .item .box span {
    width: 50%;
  }
}
